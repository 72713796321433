import React from 'react';
import Navigation from '../library/Navigation';
import { Link } from 'gatsby';
import logoImg from '../assets/images/logo.png';

export default function NavBar(props) {
  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent ? { className: "active" } : null
  }
  const PartialNavLink = props => (
    <Link getProps={isPartiallyActive} {...props} />
  )
  
  const logo = () => (
    <div className="logo">
      <Link to="/">
        <img src={logoImg} alt="Logo Hans Klopfenstein AG, Bedachungen und Gerüstbau"/>
        <div className="logo-text">
          <div>Hans Klopfenstein AG</div>
          <div>Bedachungen &bull; Gerüstbau</div>
        </div>
      </Link>
    </div>
  )
  
  return (
    <Navigation logo={logo()}>
      {/* <ul>
        <li><Link activeClassName="active" to="/">Home</Link></li>
        <li><PartialNavLink to="/angebot">Angebot</PartialNavLink></li>
        <li><PartialNavLink to="/kontakt">Kontakt</PartialNavLink></li>
      </ul> */}
    </Navigation>
  );
}


