import React from "react";
import Helmet from "react-helmet";
import NavBar from "../components/NavBar";
// import ContactBar from '../components/ContactBar';
import Footer from "../components/Footer";

import "../assets/styles/site.scss";

const Layout = ({ children, location }) => (
  <div className="layout">
    <Helmet>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="Hans Klopfenstein AG" />
      <meta
        property="og:url"
        content={`https://www.klopfenstein-ag.ch${location.pathname}`}
      />

      <link rel="preconnect" href="https://www.google-analytics.com" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png?v=RyQPNblqg3"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png?v=RyQPNblqg3"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png?v=RyQPNblqg3"
      />
      <link rel="manifest" href="/site.webmanifest?v=RyQPNblqg3" />
      <link
        rel="mask-icon"
        href="/safari-pinned-tab.svg?v=dLJjQLKMGB"
        color="#5bbad5"
      />
      <link rel="shortcut icon" href="/favicon.ico?v=RyQPNblqg3" />
      <meta name="apple-mobile-web-app-title" content="Klopfenstein AG" />
      <meta name="application-name" content="Klopfenstein AG" />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="theme-color" content="#ffffff" />

      <html lang="de" />
    </Helmet>
    {/*<ContactBar className="small-screen" />*/}
    <NavBar />
    {children}
    <Footer />
  </div>
);

export default Layout;
