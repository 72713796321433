import React from 'react';
import logoImg from '../assets/images/logo.png';

export default function Footer(props) {
  // const year = new Date().getFullYear();
  return (
    <footer id="footer">
      <div className='container'>
        <div className="logo"><img src={logoImg} alt="Hans Klopfenstein AG - Bedachungen und Gerüstbau in Rüti, Zürcher Oberland" /></div>
        <div className="address">
          {/* <span>&copy; {year} Hans Klopfenstein AG</span> */}
          <div>Hans Klopfenstein AG</div>
          <div>Trümmlenweg 5</div>
          <div> 8630 Rüti</div>
        </div>
        <div className="contact">
          <div>T <a href="phone:+41 55 240 27 13">+41 55 240 27 13</a></div>
          <div>M <a href="phone:+41 55 240 27 13">+41 79 421 59 90</a></div>
          <div><a href="mailto:info@klopfenstein-ag.ch">info@klopfenstein-ag.ch</a></div>
        </div>
        <div className="about">
          <div><a href="https://www.gh-schweiz.ch/" target="_blank" rel="noopener noreferrer">Mitglied Gebäudehülle Schweiz</a></div>
          <span>Umsetzung: <a href="https://codeplant.ch" target="_blank" rel="noopener noreferrer">codeplant GmbH</a></span>
        </div>
      </div>
    </footer>
  );
}


